body, html {
  height: 100%;
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #7A59AB; /* Background color */
}

.login-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.1);
}

.login-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 100%;
  max-width: 400px;
  transition: transform 0.3s;
}

.login-container:hover {
  transform: translateY(-5px);
}

.login-container h1 {
  color: #5A3D82;
  margin-bottom: 20px;
  font-size: 24px;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #7A59AB; 
  outline: none;
}

.login-button {
  width: 100%;
  padding: 12px;
  color: #ffffff;
  background-color: #7A59AB;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
}

.login-button:hover {
  background-color: #8366af;
  transform: translateY(-2px);
}

.password-field {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #7A59AB;
}

.messenger-page {
  padding: 40px;
  max-width: 600px;
  margin: auto;
  background: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.messenger-page:hover {
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.page-title {
  color: #5A3D82;
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
}

.messenger-form {
  display: flex;
  flex-direction: column;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #7A59AB; 
  outline: none;
}

.track-selection {
  margin: 15px 0;
}

.track-option {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.link-toggle {
  margin: 15px 0;
  font-weight: 500;
}

.link-inputs {
  margin-top: 10px;
}

.message-section {
  margin-top: 15px;
}

.message-textarea {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  height: 80px; /* Adjust height as needed */
  resize: none; /* Prevent resizing */
  transition: border-color 0.3s;
}

.message-textarea:focus {
  border-color: #7A59AB; 
  outline: none;
}

.add-paragraph-button {
  background-color: #7A59AB;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 15px;
  margin-top: 10px;
  transition: background-color 0.3s, transform 0.2s;
}

.add-paragraph-button:hover {
  background-color: #5a3d82;
  transform: translateY(-2px);
}

.submit-button {
  padding: 12px 15px;
  background-color: #7A59AB;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #5a3d82;
  transform: translateY(-2px);
}